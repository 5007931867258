import React, { useState, useEffect } from "react";
import { uniqBy, all, any } from "ramda";
import axios from "axios";
import parser from "fast-xml-parser";
import { isTrue } from "utils/helper";

function useFeed(url) {
  const [feed, setFeed] = useState({
    isLoading: false,
    isError: false,
    isReady: false,
    data: null
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFeed({ isReady: false, isLoading: true, isError: false });
        const result = await axios(url);
        const json = parser.parse(result.data);
        const data = json.rss.channel.item.filter(
          node => node.language === "en"
        );
        setFeed({
          ...feed,
          isLoading: false,
          isError: false,
          isReady: true,
          data: { ...json.rss.channel, item: data }
        });
      } catch (error) {
        setFeed({ ...feed, isLoading: false, isError: true });
      }
    };
    fetchData();
  }, [url]);

  return feed;
}

function useFeeds() {
  return [
    { id: "syrian-archive", title: "Syrian Archive", feed: useFeed("https://syrianarchive.org/rss.xml") },
    { id: "yemeni-archive", title: "Yemeni Archive", feed: useFeed("https://yemeniarchive.org/rss.xml") },
    { id: "sudanese-archive", title: "Sudanese Archive", feed: useFeed("https://sudanesearchive.org/rss.xml") },
    { id: "ukrainian-archive", title: "Ukrainian Archive", feed: useFeed("https://ukrainianarchive.org/rss.xml") },
  ];
}

export default useFeeds;
