import React, { useContext } from "react";
import MdxLink from "./mdxLink";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { rhythm } from "utils/typography";
import { localizedDate, mq, extractUrl } from "utils/helper";
import { toLower } from "ramda";
import styled from "@emotion/styled";
import { colors } from "style/theme";
import { LocaleContext } from "context/locale-context";

function color(title) {
  switch (toLower(title)) {
    case "syrian archive":
      return "#ff5400";
    case "yemeni archive":
      return "#b32c50";
    case "sudanese archive":
      return "#1283BF";
    case "ukrainian archive":
      return "#18B975";
    case "mnemonic":
      return colors.primary;
    default:
      return "#000";
  }
}

const A = styled.a({});

function Card({ node, page }) {
  // The node could come from RSS or MD files
  let title, image, description, pubDate, site, link;

  if (node.frontmatter) {
    title = node.frontmatter.title;
    image = node.frontmatter.image;
    description = node.frontmatter.desc;
    pubDate = node.frontmatter.date;
    site = "Mnemonic";
    link = node.fields.slug;
  } else {
    ({ title, image, description, pubDate, site, link } = node);
  }
  const isInternal = site === "Mnemonic";
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";

  return (
    <div
      css={{
        position: "relative",
        maxWidth: rhythm(11.1),
        direction: isRTL ? "rtl" : "ltr",
        [mq[0]]: {
          //margin: "auto",
          marginLeft: "2.2rem",
          marginBottom: rhythm(2),
          width: "100%"
        }
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          marginBottom: rhythm(0.2)
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <a
            as={isInternal ? MdxLink : A}
            href={isInternal ? "" : extractUrl(link)}
            rel={isInternal || "noopener noreferrer"}
            target={isInternal ? "_self" : "_blank"}
          >
            <small
              css={{
                color: color(site),
                textTransform: "capitalize",
                marginLeft: isRTL ? "0" : rhythm(0.3),
                marginRight: isRTL ? rhythm(0.3) : "0",
                marginTop: rhythm(0.3),
                fontWeight: "bold"
              }}
            >
              {site}
            </small>
          </a>
        </div>
      </div>
      <MdxLink
        css={{
          boxShadow: `none`
        }}
        href={link}
        rel={isInternal || "noopener noreferrer"}
        target={isInternal ? "self" : "blank"}
      >
        <div
          css={{
            position: "relative",
            "&::before": {
              content: "''",
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 1,
              top: 0,
              left: isRTL ? "auto" : 0,
              right: isRTL ? 0 : "auto",
              backgroundImage:
                "linear-gradient(180deg, rgba(0,0,0,0.34) 0%, rgba(0,0,0,0.78) 100%)"
            }
          }}
        >
          <img
            src={image?.childImageSharp?.fluid?.src || image}
            css={{
              width: "258px",
              maxHeight: "145px",
              height: "145px",
              objectFit: "cover",
              borderBottom: `6px solid ${colors.dark}`,
              marginBottom: 0
            }}
          />
        </div>
      </MdxLink>
      <MdxLink
        css={{ boxShadow: `none` }}
        href={link}
        rel="noopener noreferrer"
        target={isInternal ? "_self" : "_blank"}
      >
        <h3
          css={{
            position: "absolute",
            top: "55px",
            left: isRTL ? "auto" : "15px",
            right: isRTL ? "15px" : "auto",
            maxWidth: rhythm(11.1),
            color: colors.light,
            zIndex: 3,
            lineHeight: "22px",
            fontSize: "20px",
            fontWeight: "bold",
            margin: 0,
            transition: "0.25s",
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            ":hover, :active": {
              textDecoration: "underline"
            }
          }}
        >
          {title}
        </h3>
      </MdxLink>
      <small
        css={{
          display: "inline-block",
          color: colors.primary,
          fontWeight: 500,
          marginBottom: rhythm(0.4)
        }}
      >
        {localizedDate(pubDate, "d,m,y", isRTL)}
      </small>
      <p
        css={{
          maxWidth: rhythm(12),
          marginBottom: rhythm(0.5),
          color: "white"
        }}
      >
        {description}
      </p>
    </div>
  );
}

Card.propTypes = {
  node: PropTypes.object
};

export default Card;
